export function formatNumber(num, thousandSeparator = ',', decimalSeparator = '.', prefix = '', suffix = '') {
  return (
    prefix +
    num
      .toFixed(2)
      .replace('.00', '')
      .replace('.', decimalSeparator)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator}`) +
    suffix
  );
}
