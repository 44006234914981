import { ExclamationIcon } from '@heroicons/react/outline';

function Error({ errorMessage }) {
  return (
    <div className="flex justify-center py-20">
      <div className="flex justify-center items-center border border-red-200 rounded-md py-2 px-8 bg-red-100">
        <ExclamationIcon className="text-red-400 h-5 w-5 mr-2" />
        <div className="text-sm text-red-400">{errorMessage}</div>
      </div>
    </div>
  );
}

export default Error;
