import { Link } from 'react-router-dom';

import ImmoLogo from '../../../assets/img/logo-expat-immo.png';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

function Header() {
  return (
    <div className="grid grid-cols-3 md:grid-cols-2 gap-2 pb-3 border-b border-gray-200">
      <div className="w-16 sm:w-20 md:w-24">
        <Link to="/">
          <img src={ImmoLogo} alt="Expat Immo" />
        </Link>
      </div>
      <div className="col-span-2 md:col-auto flex flex-col justify-center items-end text-xs md:text-sm">
        <div className="text-immo font-bold leading-relaxed">William Demoustier</div>
        <div className="text-immo-secondary">
          <a href="mailto:william@expat-immo.com" className="hover:text-immo-light">
            <MailIcon className="inline h-4 w-4" /> william@expat-immo.com
          </a>
        </div>
        <div className="text-immo-secondary leading-relaxed md:leading-normal">
          <a href="https://wa.me/6590829380" className="hover:text-immo-light" target="_blank" rel="noreferrer">
            <PhoneIcon className="inline h-4 w-4" /> +65 9082 9380
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
