/* eslint-disable no-async-promise-executor */
import $axios from './customAxios';

function handleHttpError(error) {
  const serverErrorCode = [400, 403, 404, 422];
  const genericErrorMessage = 'Failed to process your request.';

  if (error.response && error.response.data) {
    let responseData = null;
    if (serverErrorCode.includes(error.response.status)) {
      if (typeof error.response.data === 'string') {
        responseData = error.response.data;
      } else {
        responseData = error.response.data.error?.message;
      }
    } else if (error.response.status === 401) {
      responseData = 'Unauthorized';
    } else if (error.response.data.error) {
      responseData = error.response.data.error.message;
    } else {
      responseData = error.response.data;
    }

    return responseData || genericErrorMessage;
  }

  return genericErrorMessage;
}

function makeHttpRequest(apiCall) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiCall();
      resolve(data.data);
    } catch (e) {
      reject(handleHttpError(e));
    }
  });
}

export function getRequest(path, options = {}) {
  return makeHttpRequest(() => $axios.get(path, options));
}

export function postRequest(path, data, options = {}) {
  return makeHttpRequest(() => $axios.post(path, data, options));
}

export function putRequest(path, data, options = {}) {
  return makeHttpRequest(() => $axios.put(path, data, options));
}

export function patchRequest(path, data, options = {}) {
  return makeHttpRequest(() => $axios.patch(path, data, options));
}

export function deleteRequest(path, options = {}) {
  return makeHttpRequest(() => $axios.delete(path, options));
}
