import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowSmRightIcon } from '@heroicons/react/outline';

import * as global from '../../constants/global';
import { ApiHelper } from '../../helpers';

function HomePage() {
  const [biens, setBiens] = useState([]);
  document.title = global.websiteName;

  useEffect(() => {
    let hasCanceled = false;
    ApiHelper.getRequest('biens').then((data) => {
      if (!hasCanceled) {
        setBiens(data);
      }
    });

    return () => {
      hasCanceled = true;
    };
  }, []);

  return (
    <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 sm:gap-2 md:gap-4">
      {React.Children.toArray(
        biens.map((bien) => (
          <div className="w-full mb-4 sm:mb-0 shadow-md p-3 border-t border-r border-l border-gray-200 rounded-md text-xs">
            <div className="h-24 grid grid-cols-4 md:grid-cols-3 gap-2 pt-2 pb-3">
              <div className="col-span-3 md:col-span-2">
                <div className="font-semibold truncate mb-1">{bien.nom}</div>
                <div className="text-immo-secondary italic">{bien.type?.nom}</div>
                <p className="line-clamp-2">{bien.Adresse}</p>
              </div>
              <div className="rounded-full overflow-hidden mx-auto h-14 w-14 md:h-16 md:w-16">
                {bien.photo[0]?.formats?.thumbnail?.url ? (
                  <img
                    src={bien.photo[0].formats.thumbnail.url}
                    alt={bien.photo[0].alternativeText}
                    className="object-cover h-full w-full"
                  />
                ) : null}
              </div>
            </div>
            <div className="flex justify-center border-t border-gray-200 pt-2">
              <Link
                to={`biens/${bien.id}`}
                className="flex items-center text-immo-secondary hover:text-immo transition-colors duration-300 ease-in-out"
              >
                <span className="mr-2">View Detail</span>
                <ArrowSmRightIcon className="h-4 w-4" />
              </Link>
            </div>
          </div>
        )),
      )}
    </div>
  );
}

export default HomePage;
