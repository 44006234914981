import { Switch, Route } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

import HomePage from '../../pages/Home';
import BienPage from '../../pages/Bien';

function Layout() {
  return (
    <div className="font-sans container mx-auto py-4 px-2 lg:max-w-4xl">
      <Header />
      <Switch>
        <main className="py-8 md:pt-10 md:pb-20">
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/biens/:id">
            <BienPage />
          </Route>
        </main>
      </Switch>
      <Footer />
    </div>
  );
}

export default Layout;
