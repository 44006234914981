import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  TagIcon,
  LightningBoltIcon,
  ClipboardListIcon,
  EmojiHappyIcon,
  MapIcon,
  PhotographIcon,
} from '@heroicons/react/outline';
import { SRLWrapper } from 'simple-react-lightbox';

import * as global from '../../constants/global';
import { ApiHelper, FormatterHelper, MathHelper } from '../../helpers';

import ErrorPage from '../../components/Error';

const initialStateFinance = {
  fraisDeNotaire: 0,
  fraisExpatImmo: 0,
  fraisBancaires: 0,
  revenusAnnuels: 0,
  depensesAnnuelles: 0,
  coutDeLa: 0,
  gestionLocative: 0,
  annuiteDemprunt: 0,
  resultatAnnuel: 0,
  cashflowMensuel: 0,
  rendementBrut: 0,
  rendementNet: 0,
};

const initialStateFormData = {
  apport: 0,
  duree: 20,
  tauxAvec: 1.6,
  gestion: true,
  vacanceLocative: 1,
};

function reducerFinance(state, action) {
  return { ...state, [action.type]: action.payload };
}

function reducerFormData(state, action) {
  return { ...state, [action.type]: action.payload };
}

function BienPage() {
  const { id } = useParams();
  const [bien, setBien] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [finance, dispatchFinance] = useReducer(reducerFinance, initialStateFinance);
  const [formData, dispatchForm] = useReducer(reducerFormData, initialStateFormData);

  useEffect(() => {
    let hasCanceled = false;

    if (id) {
      ApiHelper.getRequest(`biens/${id}`)
        .then((data) => {
          if (!hasCanceled) {
            document.title = `${global.websiteName} - ${data.nom}`;
            const apport = Math.round(+data.prixconseille * 0.2);
            dispatchForm({ type: 'apport', payload: apport });
            setBien(data);
            setIsError(false);
            setErrorMessage('');
          }
        })
        .catch((err) => {
          setIsError(true);
          setErrorMessage(err);
        });
    }

    return () => {
      hasCanceled = true;
    };
  }, [id]);

  const calculation = useCallback(
    (data) => {
      if (bien) {
        const fraisDeNotaire = Math.round(+bien.prixconseille * 0.07);
        const fraisExpatImmo = Math.round(+bien.prixconseille * 0.06 + +bien.prixtravaux * 0.08);
        const fraisBancaires = Math.round(1200 + +bien.prixconseille * 0.014);
        const revenusAnnuels = (+bien.loyermensuel + +bien.Chargeslocatairesmensuelles) * 12;

        dispatchFinance({ type: 'fraisDeNotaire', payload: fraisDeNotaire });
        dispatchFinance({ type: 'fraisExpatImmo', payload: fraisExpatImmo });
        dispatchFinance({ type: 'fraisBancaires', payload: fraisBancaires });
        dispatchFinance({ type: 'revenusAnnuels', payload: revenusAnnuels });

        if (data) {
          const coutDeLa = Math.round((revenusAnnuels / 52) * +data.vacanceLocative);
          const gestionLocative = data.gestion ? Math.round(revenusAnnuels * 0.07) : 0;
          const ratePerPeriod = +data.tauxAvec / 100 / 12;
          const numOfPayment = +data.duree * 12;
          const prix = +bien.prixconseille + +bien.prixtravaux + +bien.Ameublement;
          const frais = fraisDeNotaire + fraisExpatImmo + fraisBancaires;
          const prixFrais = prix + frais;
          const presentValue = prixFrais - data.apport;
          const annuiteDemprunt = -MathHelper.pmt(ratePerPeriod, numOfPayment, presentValue);
          const taxDepenses =
            +bien.Taxefonciere + +bien.chargesannuelleproprio + +bien.assurancePNOannuelle + coutDeLa + gestionLocative;
          const depensesAnnuelles = Math.round(taxDepenses + annuiteDemprunt * 12);
          const resultatAnnuel = revenusAnnuels - depensesAnnuelles;
          const cashflowMensuel = Math.round(resultatAnnuel / 12);
          const rendementBrut = (revenusAnnuels / prixFrais) * 100;
          const rendementNet = ((revenusAnnuels - taxDepenses) / prixFrais) * 100;

          dispatchFinance({ type: 'depensesAnnuelles', payload: depensesAnnuelles });
          dispatchFinance({ type: 'coutDeLa', payload: coutDeLa });
          dispatchFinance({ type: 'gestionLocative', payload: gestionLocative });
          dispatchFinance({ type: 'annuiteDemprunt', payload: annuiteDemprunt });
          dispatchFinance({ type: 'resultatAnnuel', payload: resultatAnnuel });
          dispatchFinance({ type: 'cashflowMensuel', payload: cashflowMensuel });
          dispatchFinance({ type: 'rendementBrut', payload: rendementBrut });
          dispatchFinance({ type: 'rendementNet', payload: rendementNet });
        }
      }
    },
    [bien],
  );

  useEffect(() => {
    calculation(formData);
  }, [calculation, formData]);

  const handleChange = (event) => {
    const { target } = event;
    const { name, type, checked, value: inputValue } = target;
    let value = type === 'checkbox' ? checked : inputValue;

    if (name === 'vacanceLocative' && value > 1000) {
      value = formData.vacanceLocative;
    }

    dispatchForm({ type: name, payload: value });
  };

  return bien ? (
    <>
      <h1 className="text-center text-xl sm:text-3xl font-bold">{bien.nom}</h1>
      <p className="pt-3 pl-3 pr-3 pb-8 text-xs sm:text-sm text-justify">{bien.description}</p>
      <div className="md:grid md:grid-cols-2 md:gap-4 px-3">
        <div className="mb-8 md:mb-0">
          <div className="relative mb-4">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-immo text-white">
                <TagIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-base md:text-lg leading-6 font-medium text-immo">Type</p>
            </dt>
            <dd className="mt-1 ml-16 text-xs md:text-sm text-immo-secondary whitespace-pre-wrap">{bien.type.nom}</dd>
          </div>
          <div className="relative mb-4">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-immo text-white">
                <LightningBoltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-base md:text-lg leading-6 font-medium text-immo">Les Communs</p>
            </dt>
            <dd className="mt-1 ml-16 text-xs md:text-sm text-immo-secondary whitespace-pre-wrap">{bien.Lescommuns}</dd>
          </div>
          <div className="relative mb-4">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-immo text-white">
                <MapIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-base md:text-lg leading-6 font-medium text-immo">Adresse</p>
            </dt>
            <dd className="mt-1 ml-16 text-xs md:text-sm text-immo-secondary whitespace-pre-wrap">
              <a
                href={`http://maps.google.com/?q=${encodeURI(bien.Adresse)}`}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                {bien.Adresse}
              </a>
            </dd>
          </div>
          <div className="relative mb-4">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-immo text-white">
                <ClipboardListIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-base md:text-lg leading-6 font-medium text-immo">Travaux</p>
            </dt>
            <dd className="mt-1 ml-16 text-xs md:text-sm text-immo-secondary whitespace-pre-wrap">{bien.Travaux}</dd>
          </div>
          <div className="relative mb-4">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-immo text-white">
                <EmojiHappyIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-base md:text-lg leading-6 font-medium text-immo">Nous Aimons</p>
            </dt>
            <dd className="mt-1 ml-16 text-xs md:text-sm text-immo-secondary whitespace-pre-wrap">{bien.Nousaimons}</dd>
          </div>
          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-immo text-white">
                <PhotographIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-base md:text-lg leading-6 font-medium text-immo">Photos</p>
            </dt>
            <dd className="mt-1 ml-16">
              {bien.photo ? (
                <SRLWrapper>
                  <div className="grid grid-cols-3 md:grid-cols-4 gap-2">
                    {React.Children.toArray(
                      bien.photo.map((photo) => (
                        <div className="rounded overflow-hidden cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100">
                          <img
                            src={photo.formats.small.url}
                            alt={photo.alternativeText}
                            loading="lazy"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )),
                    )}
                  </div>
                </SRLWrapper>
              ) : null}
            </dd>
          </div>
        </div>
        <div className="relative text-xs md:text-sm mb-6">
          <div className="py-5 px-4 border border-gray-100 rounded-md shadow-lg">
            <div className="text-sm md:text-base font-semibold mb-3">Modélisation financière</div>
            <div className="border border-gray-200 rounded">
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.prixdevente, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Prix FAI</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.prixconseille, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Prix Conseillé</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.prixtravaux, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Travaux</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200  py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.Ameublement, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Ameublement</div>
              </div>

              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.fraisDeNotaire, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Frais de notaire</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.fraisExpatImmo, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Frais Expat Immo</div>
              </div>
              <div className="flex items-center justify-between py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.fraisBancaires, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Frais bancaires</div>
              </div>
            </div>

            <div className="mt-5">
              <div className="grid grid-cols-3 gap-2 mb-1">
                <div className="font-semibold col-span-2">Apport</div>
                <div className="text-immo-secondary text-right">{formData.apport} &euro;</div>
              </div>
              <input
                type="range"
                min="0"
                max={bien.prixconseille}
                step="1000"
                name="apport"
                className="rounded-lg overflow-hidden appearance-none bg-gray-200 h-4 w-full"
                value={formData.apport}
                onChange={handleChange}
              />
            </div>
            <div className="mt-2">
              <div className="grid grid-cols-3 gap-2 mb-1">
                <div className="font-semibold col-span-2">Durée d'emprunt</div>
                <div className="text-immo-secondary text-right">{formData.duree} ans</div>
              </div>
              <input
                type="range"
                min="0"
                max="30"
                name="duree"
                className="rounded-lg overflow-hidden appearance-none bg-gray-200 h-4 w-full"
                value={formData.duree}
                onChange={handleChange}
              />
            </div>
            <div className="mt-2">
              <div className="grid grid-cols-3 gap-2 mb-1">
                <div className="font-semibold col-span-2">Taux avec assurance</div>
                <div className="text-immo-secondary text-right">{formData.tauxAvec} %</div>
              </div>
              <input
                type="range"
                min="0.00"
                max="3.00"
                step="0.01"
                name="tauxAvec"
                className="rounded-lg overflow-hidden appearance-none bg-gray-200 h-4 w-full"
                value={formData.tauxAvec}
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <label className="inline-flex items-center font-semibold select-none cursor-pointer">
                <input
                  type="checkbox"
                  name="gestion"
                  className="mr-2 cursor-pointer transition-colors duration-500 ease-in-out appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-immo-light checked:border-transparent focus:outline-none"
                  checked={formData.gestion}
                  onChange={handleChange}
                />
                Gestion locative
              </label>
            </div>

            <div className="border border-gray-200 rounded mt-5">
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.loyermensuel, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Loyers HC</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.Chargeslocatairesmensuelles, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Charges locataires</div>
              </div>
              <div className="flex items-center justify-between bg-gray-100 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.revenusAnnuels, ' ', '.', '', ' €')}
                </div>
                <div className="font-medium ml-3 text-right">Revenus annuels</div>
              </div>
            </div>

            <div className="border border-gray-200 rounded mt-5">
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.Taxefonciere, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Taxe foncière</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.chargesannuelleproprio, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Charges annuelles</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(bien.assurancePNOannuelle, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Assurance PNO</div>
              </div>
              <div className="flex items-center justify-between bg-gray-100 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.depensesAnnuelles, ' ', '.', '', ' €')}
                </div>
                <div className="font-medium ml-3 text-right">Dépenses annuelles</div>
              </div>
            </div>

            <div className="mt-5">
              <div className="font-semibold">Vacance locative</div>
              <div className="flex items-center border border-gray-300 rounded overflow-hidden">
                <input
                  type="number"
                  min="0"
                  max="1000"
                  name="vacanceLocative"
                  className="py-2 px-3 flex-grow focus:outline-none"
                  value={formData.vacanceLocative}
                  onChange={handleChange}
                />
                <div className="border-l border-gray-300 bg-gray-100 py-2 px-6 text-immo-secondary font-medium">
                  Semaine(s)
                </div>
              </div>
            </div>

            <div className="border border-gray-200 rounded mt-5">
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.coutDeLa, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Cout de la vacance locative</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.gestionLocative, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Gestion locative</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.annuiteDemprunt, ' ', '.', '', ' €')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Annuité d'emprunt</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.rendementBrut, ' ', '.', '', ' %')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Rendement brut</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.rendementNet, ' ', '.', '', ' %')}
                </div>
                <div className="text-immo-secondary ml-3 text-right">Rendement net avant impôt</div>
              </div>
              <div className="flex items-center justify-between border-b border-gray-200 bg-gray-100 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.resultatAnnuel, ' ', '.', '', ' €')}
                </div>
                <div className="font-medium ml-3 text-right">Résultat annuel</div>
              </div>
              <div className="flex items-center justify-between bg-gray-100 py-2 px-3">
                <div className="font-medium flex-shrink-0">
                  {FormatterHelper.formatNumber(finance.cashflowMensuel, ' ', '.', '', ' €')}
                </div>
                <div className="font-medium ml-3 text-right">Cashflow mensuel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>{isError ? <ErrorPage errorMessage={errorMessage} /> : null}</>
  );
}

export default BienPage;
